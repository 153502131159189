import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "World Championship RR",
      edition: 2021,
      what: "Race data",
      updated: "2021-09-25 09:09:41",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      Albania: {
        teamId: "Albania",
        teamUciCode: "",
        teamName: "Albania",
        teamNationCode: "ALB",
      },
      Algeria: {
        teamId: "Algeria",
        teamUciCode: "",
        teamName: "Algeria",
        teamNationCode: "ALG",
      },
      Australia: {
        teamId: "Australia",
        teamUciCode: "",
        teamName: "Australia",
        teamNationCode: "AUS",
      },
      Austria: {
        teamId: "Austria",
        teamUciCode: "",
        teamName: "Austria",
        teamNationCode: "AUT",
      },
      Belarus: {
        teamId: "Belarus",
        teamUciCode: "",
        teamName: "Belarus",
        teamNationCode: "BLR",
      },
      Belgium: {
        teamId: "Belgium",
        teamUciCode: "",
        teamName: "Belgium",
        teamNationCode: "BEL",
      },
      Canada: {
        teamId: "Canada",
        teamUciCode: "",
        teamName: "Canada",
        teamNationCode: "CAN",
      },
      Colombia: {
        teamId: "Colombia",
        teamUciCode: "",
        teamName: "Colombia",
        teamNationCode: "COL",
      },
      "Czech Republic": {
        teamId: "Czech Republic",
        teamUciCode: "",
        teamName: "Czech Republic",
        teamNationCode: "CZE",
      },
      Denmark: {
        teamId: "Denmark",
        teamUciCode: "",
        teamName: "Denmark",
        teamNationCode: "DEN",
      },
      Ecuador: {
        teamId: "Ecuador",
        teamUciCode: "",
        teamName: "Ecuador",
        teamNationCode: "ECU",
      },
      Eritrea: {
        teamId: "Eritrea",
        teamUciCode: "",
        teamName: "Eritrea",
        teamNationCode: "ERI",
      },
      Estonia: {
        teamId: "Estonia",
        teamUciCode: "",
        teamName: "Estonia",
        teamNationCode: "EST",
      },
      Finland: {
        teamId: "Finland",
        teamUciCode: "",
        teamName: "Finland",
        teamNationCode: "FIN",
      },
      France: {
        teamId: "France",
        teamUciCode: "",
        teamName: "France",
        teamNationCode: "FRA",
      },
      Germany: {
        teamId: "Germany",
        teamUciCode: "",
        teamName: "Germany",
        teamNationCode: "GER",
      },
      "Great Britain": {
        teamId: "Great Britain",
        teamUciCode: "",
        teamName: "Great Britain",
        teamNationCode: "GBR",
      },
      Greece: {
        teamId: "Greece",
        teamUciCode: "",
        teamName: "Greece",
        teamNationCode: "GRE",
      },
      Hungary: {
        teamId: "Hungary",
        teamUciCode: "",
        teamName: "Hungary",
        teamNationCode: "HUN",
      },
      Ireland: {
        teamId: "Ireland",
        teamUciCode: "",
        teamName: "Ireland",
        teamNationCode: "IRL",
      },
      Italy: {
        teamId: "Italy",
        teamUciCode: "",
        teamName: "Italy",
        teamNationCode: "ITA",
      },
      Japan: {
        teamId: "Japan",
        teamUciCode: "",
        teamName: "Japan",
        teamNationCode: "JPN",
      },
      Kazakhstan: {
        teamId: "Kazakhstan",
        teamUciCode: "",
        teamName: "Kazakhstan",
        teamNationCode: "KAZ",
      },
      Latvia: {
        teamId: "Latvia",
        teamUciCode: "",
        teamName: "Latvia",
        teamNationCode: "LAT",
      },
      Lithuania: {
        teamId: "Lithuania",
        teamUciCode: "",
        teamName: "Lithuania",
        teamNationCode: "LTU",
      },
      Luxembourg: {
        teamId: "Luxembourg",
        teamUciCode: "",
        teamName: "Luxembourg",
        teamNationCode: "LUX",
      },
      Mexico: {
        teamId: "Mexico",
        teamUciCode: "",
        teamName: "Mexico",
        teamNationCode: "MEX",
      },
      Mongolia: {
        teamId: "Mongolia",
        teamUciCode: "",
        teamName: "Mongolia",
        teamNationCode: "MGL",
      },
      Netherlands: {
        teamId: "Netherlands",
        teamUciCode: "",
        teamName: "Netherlands",
        teamNationCode: "NED",
      },
      "New Zealand": {
        teamId: "New Zealand",
        teamUciCode: "",
        teamName: "New Zealand",
        teamNationCode: "NZL",
      },
      Norway: {
        teamId: "Norway",
        teamUciCode: "",
        teamName: "Norway",
        teamNationCode: "NOR",
      },
      Panama: {
        teamId: "Panama",
        teamUciCode: "",
        teamName: "Panama",
        teamNationCode: "PAN",
      },
      Poland: {
        teamId: "Poland",
        teamUciCode: "",
        teamName: "Poland",
        teamNationCode: "POL",
      },
      Portugal: {
        teamId: "Portugal",
        teamUciCode: "",
        teamName: "Portugal",
        teamNationCode: "POR",
      },
      Romania: {
        teamId: "Romania",
        teamUciCode: "",
        teamName: "Romania",
        teamNationCode: "ROM",
      },
      Russia: {
        teamId: "Russia",
        teamUciCode: "",
        teamName: "Russia",
        teamNationCode: "RUS",
      },
      Slovakia: {
        teamId: "Slovakia",
        teamUciCode: "",
        teamName: "Slovakia",
        teamNationCode: "SVK",
      },
      Slovenia: {
        teamId: "Slovenia",
        teamUciCode: "",
        teamName: "Slovenia",
        teamNationCode: "SLO",
      },
      "South Africa": {
        teamId: "South Africa",
        teamUciCode: "",
        teamName: "South Africa",
        teamNationCode: "RSA",
      },
      Spain: {
        teamId: "Spain",
        teamUciCode: "",
        teamName: "Spain",
        teamNationCode: "ESP",
      },
      Sweden: {
        teamId: "Sweden",
        teamUciCode: "",
        teamName: "Sweden",
        teamNationCode: "SWE",
      },
      Switzerland: {
        teamId: "Switzerland",
        teamUciCode: "",
        teamName: "Switzerland",
        teamNationCode: "SUI",
      },
      Thailand: {
        teamId: "Thailand",
        teamUciCode: "",
        teamName: "Thailand",
        teamNationCode: "THA",
      },
      Ukraine: {
        teamId: "Ukraine",
        teamUciCode: "",
        teamName: "Ukraine",
        teamNationCode: "UKR",
      },
      Uruguay: {
        teamId: "Uruguay",
        teamUciCode: "",
        teamName: "Uruguay",
        teamNationCode: "URU",
      },
      USA: {
        teamId: "USA",
        teamUciCode: "",
        teamName: "USA",
        teamNationCode: "USA",
      },
      Venezuela: {
        teamId: "Venezuela",
        teamUciCode: "",
        teamName: "Venezuela",
        teamNationCode: "VEN",
      },
    },
    riders: {
      63055: {
        id: 63055,
        startno: 41,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      16910: {
        id: 16910,
        startno: 189,
        firstName: "Eder",
        lastName: "Frayre",
        nationCode: "MEX",
        nationName: "Mexico",
        birthDate: "1991-10-20",
        teamName: "Mexico",
        teamId: "Mexico",
        stillInTheRace: "Y",
      },
      14778: {
        id: 14778,
        startno: 186,
        firstName: "Eduard Michael",
        lastName: "Grosu",
        nationCode: "ROM",
        nationName: "Romania",
        birthDate: "1992-09-04",
        teamName: "Romania",
        teamId: "Romania",
        stillInTheRace: "Y",
      },
      37494: {
        id: 37494,
        startno: 127,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 129,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      22630: {
        id: 22630,
        startno: 164,
        firstName: "Metkel",
        lastName: "Eyob",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1993-09-04",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      872: {
        id: 872,
        startno: 62,
        firstName: "Gorka",
        lastName: "Izagirre",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-10-07",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      6579: {
        id: 6579,
        startno: 188,
        firstName: "Polychronis",
        lastName: "Tzortzakis",
        nationCode: "GRE",
        nationName: "Greece",
        birthDate: "1989-01-03",
        teamName: "Greece",
        teamId: "Greece",
        stillInTheRace: "Y",
      },
      7730: {
        id: 7730,
        startno: 49,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      16689: {
        id: 16689,
        startno: 21,
        firstName: "David",
        lastName: "Per",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-02-13",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      37286: {
        id: 37286,
        startno: 98,
        firstName: "Andr\u00e9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1997-10-31",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      18575: {
        id: 18575,
        startno: 75,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      37384: {
        id: 37384,
        startno: 154,
        firstName: "Norman",
        lastName: "Vahtra",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1996-11-23",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      2338: {
        id: 2338,
        startno: 28,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      9932: {
        id: 9932,
        startno: 139,
        firstName: "Shane",
        lastName: "Archbold",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1989-02-02",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      46163: {
        id: 46163,
        startno: 90,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      7208: {
        id: 7208,
        startno: 68,
        firstName: "Nathan",
        lastName: "Haas",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1989-03-12",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      10103: {
        id: 10103,
        startno: 167,
        firstName: "Josef",
        lastName: "Cern\u00fd",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1993-05-11",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      37379: {
        id: 37379,
        startno: 6,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      6234: {
        id: 6234,
        startno: 155,
        firstName: "Daniil",
        lastName: "Fominykh",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1991-08-28",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 119,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      9107: {
        id: 9107,
        startno: 162,
        firstName: "Patrik",
        lastName: "Tybor",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1987-09-16",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 137,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      6309: {
        id: 6309,
        startno: 53,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      2313: {
        id: 2313,
        startno: 37,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      3930: {
        id: 3930,
        startno: 178,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      11000: {
        id: 11000,
        startno: 97,
        firstName: "Rafael",
        lastName: "Reis",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1992-07-15",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      75665: {
        id: 75665,
        startno: 192,
        firstName: "Antonio Eric",
        lastName: "Fagundez",
        nationCode: "URU",
        nationName: "Uruguay",
        birthDate: "1998-08-19",
        teamName: "Uruguay",
        teamId: "Uruguay",
        stillInTheRace: "Y",
      },
      5059: {
        id: 5059,
        startno: 15,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      62834: {
        id: 62834,
        startno: 107,
        firstName: "Wilson",
        lastName: "Haro",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1998-01-05",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      73811: {
        id: 73811,
        startno: 58,
        firstName: "Roger",
        lastName: "Adri\u00e1",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-04-18",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 84,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      581: {
        id: 581,
        startno: 44,
        firstName: "Bauke",
        lastName: "Mollema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-11-26",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      14460: {
        id: 14460,
        startno: 150,
        firstName: "Jayde",
        lastName: "Julius",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1993-08-27",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      21855: {
        id: 21855,
        startno: 71,
        firstName: "Miles",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-01-18",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 132,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      32201: {
        id: 32201,
        startno: 65,
        firstName: "Antonio",
        lastName: "Soto",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-09-22",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      16646: {
        id: 16646,
        startno: 145,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      16807: {
        id: 16807,
        startno: 101,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      18655: {
        id: 18655,
        startno: 24,
        firstName: "Primoz",
        lastName: "Roglic",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1989-10-29",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      37278: {
        id: 37278,
        startno: 115,
        firstName: "Petr",
        lastName: "Rikunov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1997-02-24",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      25505: {
        id: 25505,
        startno: 194,
        firstName: "Ylber",
        lastName: "Sefa",
        nationCode: "ALB",
        nationName: "Albania",
        birthDate: "1991-02-11",
        teamName: "Albania",
        teamId: "Albania",
        stillInTheRace: "Y",
      },
      41293: {
        id: 41293,
        startno: 78,
        firstName: "Alvaro",
        lastName: "Hodeg",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-09-16",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      27111: {
        id: 27111,
        startno: 2,
        firstName: "Remi",
        lastName: "Cavagna",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-08-10",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      2982: {
        id: 2982,
        startno: 31,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 142,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      327: {
        id: 327,
        startno: 93,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      44537: {
        id: 44537,
        startno: 72,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      6266: {
        id: 6266,
        startno: 170,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 9,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      37339: {
        id: 37339,
        startno: 158,
        firstName: "Yuriy",
        lastName: "Natarov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-12-28",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      7771: {
        id: 7771,
        startno: 122,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      14935: {
        id: 14935,
        startno: 190,
        firstName: "Sarawut",
        lastName: "Sirironnachai",
        nationCode: "THA",
        nationName: "Thailand",
        birthDate: "1992-08-23",
        teamName: "Thailand",
        teamId: "Thailand",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 11,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 56,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 40,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      8740: {
        id: 8740,
        startno: 183,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      16685: {
        id: 16685,
        startno: 116,
        firstName: "Dmitriy",
        lastName: "Strakhov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1995-05-17",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      48539: {
        id: 48539,
        startno: 126,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      16587: {
        id: 16587,
        startno: 185,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamName: "Belarus",
        teamId: "Belarus",
        stillInTheRace: "Y",
      },
      6257: {
        id: 6257,
        startno: 163,
        firstName: "Natnael",
        lastName: "Berhane",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1991-01-05",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 61,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      38803: {
        id: 38803,
        startno: 87,
        firstName: "Georg",
        lastName: "Zimmermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-11",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 48,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      27210: {
        id: 27210,
        startno: 20,
        firstName: "Domen",
        lastName: "Novak",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1995-07-24",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      2320: {
        id: 2320,
        startno: 113,
        firstName: "Pavel",
        lastName: "Kochetkov",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1986-03-07",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      3213: {
        id: 3213,
        startno: 74,
        firstName: "Esteban",
        lastName: "Chaves",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-01-17",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      16631: {
        id: 16631,
        startno: 153,
        firstName: "Oskar",
        lastName: "Nisu",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1994-08-11",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      26504: {
        id: 26504,
        startno: 27,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      37303: {
        id: 37303,
        startno: 104,
        firstName: "Rasmus",
        lastName: "Tiller",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-07-28",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 89,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      6563: {
        id: 6563,
        startno: 174,
        firstName: "Oleksandr",
        lastName: "Prevar",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1990-06-28",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      16573: {
        id: 16573,
        startno: 67,
        firstName: "Caleb",
        lastName: "Ewan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-07-11",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      117: {
        id: 117,
        startno: 81,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 5,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 195,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamName: "Japan",
        teamId: "Japan",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 118,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      784: {
        id: 784,
        startno: 161,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      9894: {
        id: 9894,
        startno: 136,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      37401: {
        id: 37401,
        startno: 52,
        firstName: "Mikkel",
        lastName: "Honore",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-01-21",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      65025: {
        id: 65025,
        startno: 36,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      45606: {
        id: 45606,
        startno: 177,
        firstName: "Martins",
        lastName: "Pluto",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1998-01-13",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      14325: {
        id: 14325,
        startno: 96,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      17172: {
        id: 17172,
        startno: 173,
        firstName: "Andrii",
        lastName: "Kulyk",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1989-08-30",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      10971: {
        id: 10971,
        startno: 14,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      5683: {
        id: 5683,
        startno: 106,
        firstName: "Byron",
        lastName: "Guama",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1985-06-14",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      77955: {
        id: 77955,
        startno: 166,
        firstName: "Dawit",
        lastName: "Yemane",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1998-01-01",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      5306: {
        id: 5306,
        startno: 83,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      312: {
        id: 312,
        startno: 43,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      6559: {
        id: 6559,
        startno: 149,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1989-02-03",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      37221: {
        id: 37221,
        startno: 193,
        firstName: "Orluis",
        lastName: "Aular",
        nationCode: "VEN",
        nationName: "Venezuela",
        birthDate: "1996-11-05",
        teamName: "Venezuela",
        teamId: "Venezuela",
        stillInTheRace: "Y",
      },
      6260: {
        id: 6260,
        startno: 131,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      25690: {
        id: 25690,
        startno: 64,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      27283: {
        id: 27283,
        startno: 144,
        firstName: "Edward",
        lastName: "Dunbar",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1996-09-01",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      17252: {
        id: 17252,
        startno: 100,
        firstName: "Odd Christian",
        lastName: "Eiking",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-28",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      6538: {
        id: 6538,
        startno: 23,
        firstName: "Jan",
        lastName: "Polanc",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1992-05-06",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      9120: {
        id: 9120,
        startno: 111,
        firstName: "Igor",
        lastName: "Boev",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1989-11-22",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 77,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      12474: {
        id: 12474,
        startno: 1,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      28781: {
        id: 28781,
        startno: 30,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      61463: {
        id: 61463,
        startno: 141,
        firstName: "Connor",
        lastName: "Brown",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1998-08-06",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 92,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      16576: {
        id: 16576,
        startno: 70,
        firstName: "Nick",
        lastName: "Schultz",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-09-13",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 169,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      12481: {
        id: 12481,
        startno: 8,
        firstName: "Florian",
        lastName: "S\u00e9n\u00e9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 157,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 121,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      4317: {
        id: 4317,
        startno: 180,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      16786: {
        id: 16786,
        startno: 10,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      7695: {
        id: 7695,
        startno: 55,
        firstName: "Magnus",
        lastName: "Cort",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1993-01-16",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      828: {
        id: 828,
        startno: 39,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      37325: {
        id: 37325,
        startno: 182,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamName: "Luxembourg",
        teamId: "Luxembourg",
        stillInTheRace: "Y",
      },
      16683: {
        id: 16683,
        startno: 114,
        firstName: "Artem",
        lastName: "Nych",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1995-03-21",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      68206: {
        id: 68206,
        startno: 125,
        firstName: "Matteo",
        lastName: "Jorgenson",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1999-07-01",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 17,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      49516: {
        id: 49516,
        startno: 109,
        firstName: "Cristian",
        lastName: "Pita",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1995-02-06",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      333: {
        id: 333,
        startno: 60,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      16643: {
        id: 16643,
        startno: 86,
        firstName: "Maximilian",
        lastName: "Schachmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-09",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 46,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 19,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      37308: {
        id: 37308,
        startno: 42,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      68291: {
        id: 68291,
        startno: 134,
        firstName: "Nickolas",
        lastName: "Zukowsky",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1998-06-03",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      37482: {
        id: 37482,
        startno: 152,
        firstName: "Karl Patrick",
        lastName: "Lauk",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1997-01-09",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      65240: {
        id: 65240,
        startno: 26,
        firstName: "Andrea",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-23",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 103,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      46157: {
        id: 46157,
        startno: 88,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      37282: {
        id: 37282,
        startno: 147,
        firstName: "Gustav",
        lastName: "Basson",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1996-02-23",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 66,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      38229: {
        id: 38229,
        startno: 80,
        firstName: "Nelson",
        lastName: "Soto",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-06-19",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      2337: {
        id: 2337,
        startno: 4,
        firstName: "Arnaud",
        lastName: "D\u00e9mare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      794: {
        id: 794,
        startno: 33,
        firstName: "Diego",
        lastName: "Ulissi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-07-15",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      38000: {
        id: 38000,
        startno: 117,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-01-20",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      2172: {
        id: 2172,
        startno: 160,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      37439: {
        id: 37439,
        startno: 135,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      66265: {
        id: 66265,
        startno: 51,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      45349: {
        id: 45349,
        startno: 35,
        firstName: "Ethan",
        lastName: "Hayter",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-09-18",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      16660: {
        id: 16660,
        startno: 176,
        firstName: "Krists",
        lastName: "Neilands",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1994-08-18",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      1008: {
        id: 1008,
        startno: 95,
        firstName: "Nelson",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1989-03-06",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      4696: {
        id: 4696,
        startno: 172,
        firstName: "Mykhaylo",
        lastName: "Kononenko",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1987-10-30",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      84019: {
        id: 84019,
        startno: 13,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      22974: {
        id: 22974,
        startno: 105,
        firstName: "Joel",
        lastName: "Burbano",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1989-07-05",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      9746: {
        id: 9746,
        startno: 82,
        firstName: "Pascal",
        lastName: "Ackermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-17",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      41160: {
        id: 41160,
        startno: 191,
        firstName: "Jambaljamats",
        lastName: "Sainbayar",
        nationCode: "MGL",
        nationName: "Mongolia",
        birthDate: "1996-09-04",
        teamName: "Mongolia",
        teamId: "Mongolia",
        stillInTheRace: "Y",
      },
      16998: {
        id: 16998,
        startno: 148,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamName: "South Africa",
        teamId: "South Africa",
        stillInTheRace: "Y",
      },
      95151: {
        id: 95151,
        startno: 128,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      37419: {
        id: 37419,
        startno: 130,
        firstName: "Pier-Andre",
        lastName: "Cote",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1997-04-24",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      18117: {
        id: 18117,
        startno: 165,
        firstName: "Merhawi",
        lastName: "Kudus",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-01-23",
        teamName: "Eritrea",
        teamId: "Eritrea",
        stillInTheRace: "Y",
      },
      84926: {
        id: 84926,
        startno: 63,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-02",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      50030: {
        id: 50030,
        startno: 184,
        firstName: "Barnabas",
        lastName: "Peak",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-11-29",
        teamName: "Hungary",
        teamId: "Hungary",
        stillInTheRace: "Y",
      },
      4953: {
        id: 4953,
        startno: 99,
        firstName: "Sven Erik",
        lastName: "Bystr\u00f8m",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1992-01-21",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      45992: {
        id: 45992,
        startno: 22,
        firstName: "Tadej",
        lastName: "Pogacar",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1998-09-21",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      72587: {
        id: 72587,
        startno: 110,
        firstName: "Alexis Benjamin",
        lastName: "Quinteros",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-10-01",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      16599: {
        id: 16599,
        startno: 76,
        firstName: "Jose",
        lastName: "Hernandez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-05-08",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      37761: {
        id: 37761,
        startno: 1000,
        firstName: "Jaakko",
        lastName: "H\u00e4nninen",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1997-04-16",
        teamName: "Finland",
        teamId: "Finland",
        stillInTheRace: "Y",
      },
      3103: {
        id: 3103,
        startno: 29,
        firstName: "Alessandro",
        lastName: "De Marchi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1986-05-19",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      2212: {
        id: 2212,
        startno: 140,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamName: "New Zealand",
        teamId: "New Zealand",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 91,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamName: "Switzerland",
        teamId: "Switzerland",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 69,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      16211: {
        id: 16211,
        startno: 168,
        firstName: "Michael",
        lastName: "Kukrle",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1994-11-17",
        teamName: "Czech Republic",
        teamId: "Czech Republic",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 7,
        firstName: "Cl\u00e9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      27257: {
        id: 27257,
        startno: 156,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamName: "Kazakhstan",
        teamId: "Kazakhstan",
        stillInTheRace: "Y",
      },
      926: {
        id: 926,
        startno: 120,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-04-29",
        teamName: "Poland",
        teamId: "Poland",
        stillInTheRace: "Y",
      },
      27197: {
        id: 27197,
        startno: 179,
        firstName: "Lucas",
        lastName: "Eriksson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1996-10-04",
        teamName: "Sweden",
        teamId: "Sweden",
        stillInTheRace: "Y",
      },
      8388: {
        id: 8388,
        startno: 138,
        firstName: "Lukas",
        lastName: "P\u00f6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1992-01-10",
        teamName: "Austria",
        teamId: "Austria",
        stillInTheRace: "Y",
      },
      53723: {
        id: 53723,
        startno: 54,
        firstName: "Andreas",
        lastName: "Kron",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-06-01",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      65036: {
        id: 65036,
        startno: 38,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-10-02",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      7755: {
        id: 7755,
        startno: 187,
        firstName: "Evaldas",
        lastName: "Siskevicius",
        nationCode: "LTU",
        nationName: "Lithuania",
        birthDate: "1988-12-30",
        teamName: "Lithuania",
        teamId: "Lithuania",
        stillInTheRace: "Y",
      },
      6526: {
        id: 6526,
        startno: 112,
        firstName: "Sergey",
        lastName: "Chernetskiy",
        nationCode: "RUS",
        nationName: "Russia",
        birthDate: "1990-04-09",
        teamName: "Russia",
        teamId: "Russia",
        stillInTheRace: "Y",
      },
      3355: {
        id: 3355,
        startno: 124,
        firstName: "Lawson",
        lastName: "Craddock",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-02-20",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 16,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      23120: {
        id: 23120,
        startno: 108,
        firstName: "Sebastian",
        lastName: "Novoa",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1992-11-06",
        teamName: "Ecuador",
        teamId: "Ecuador",
        stillInTheRace: "Y",
      },
      27307: {
        id: 27307,
        startno: 59,
        firstName: "Alex",
        lastName: "Aranburu",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-09-19",
        teamName: "Spain",
        teamId: "Spain",
        stillInTheRace: "Y",
      },
      18260: {
        id: 18260,
        startno: 85,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamName: "Germany",
        teamId: "Germany",
        stillInTheRace: "Y",
      },
      4779: {
        id: 4779,
        startno: 45,
        firstName: "Oscar",
        lastName: "Riesebeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-12-23",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 18,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 47,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamName: "Netherlands",
        teamId: "Netherlands",
        stillInTheRace: "Y",
      },
      16594: {
        id: 16594,
        startno: 133,
        firstName: "Ben",
        lastName: "Perry",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1994-03-07",
        teamName: "Canada",
        teamId: "Canada",
        stillInTheRace: "Y",
      },
      16506: {
        id: 16506,
        startno: 151,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1993-09-15",
        teamName: "Estonia",
        teamId: "Estonia",
        stillInTheRace: "Y",
      },
      42868: {
        id: 42868,
        startno: 146,
        firstName: "Rory",
        lastName: "Townsend",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1995-06-30",
        teamName: "Ireland",
        teamId: "Ireland",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 102,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamName: "Norway",
        teamId: "Norway",
        stillInTheRace: "Y",
      },
      3050: {
        id: 3050,
        startno: 25,
        firstName: "Jan",
        lastName: "Tratnik",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1990-02-23",
        teamName: "Slovenia",
        teamId: "Slovenia",
        stillInTheRace: "Y",
      },
      9049: {
        id: 9049,
        startno: 123,
        firstName: "Robin",
        lastName: "Carpenter",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1992-06-20",
        teamName: "USA",
        teamId: "USA",
        stillInTheRace: "Y",
      },
      6422: {
        id: 6422,
        startno: 1001,
        firstName: "Azzedine",
        lastName: "Lagab",
        nationCode: "ALG",
        nationName: "Algeria",
        birthDate: "1988-06-19",
        teamName: "Algeria",
        teamId: "Algeria",
        stillInTheRace: "Y",
      },
      34624: {
        id: 34624,
        startno: 79,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamName: "Colombia",
        teamId: "Colombia",
        stillInTheRace: "Y",
      },
      34011: {
        id: 34011,
        startno: 3,
        firstName: "Beno\u00eet",
        lastName: "Cosnefroy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-10-17",
        teamName: "France",
        teamId: "France",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 32,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamName: "Italy",
        teamId: "Italy",
        stillInTheRace: "Y",
      },
      41242: {
        id: 41242,
        startno: 181,
        firstName: "Franklin",
        lastName: "Archibold Castillo",
        nationCode: "PAN",
        nationName: "Panama",
        birthDate: "1997-08-24",
        teamName: "Panama",
        teamId: "Panama",
        stillInTheRace: "Y",
      },
      16694: {
        id: 16694,
        startno: 159,
        firstName: "Erik",
        lastName: "Baska",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1994-01-12",
        teamName: "Slovakia",
        teamId: "Slovakia",
        stillInTheRace: "Y",
      },
      44622: {
        id: 44622,
        startno: 73,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamName: "Australia",
        teamId: "Australia",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 50,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
      14: {
        id: 14,
        startno: 34,
        firstName: "Mark",
        lastName: "Cavendish",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1985-05-21",
        teamName: "Great Britain",
        teamId: "Great Britain",
        stillInTheRace: "Y",
      },
      8813: {
        id: 8813,
        startno: 175,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1992-10-29",
        teamName: "Latvia",
        teamId: "Latvia",
        stillInTheRace: "Y",
      },
      50303: {
        id: 50303,
        startno: 94,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamName: "Portugal",
        teamId: "Portugal",
        stillInTheRace: "Y",
      },
      17008: {
        id: 17008,
        startno: 171,
        firstName: "Anatolii",
        lastName: "Budiak",
        nationCode: "UKR",
        nationName: "Ukraine",
        birthDate: "1995-09-29",
        teamName: "Ukraine",
        teamId: "Ukraine",
        stillInTheRace: "Y",
      },
      1970: {
        id: 1970,
        startno: 12,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamName: "Belgium",
        teamId: "Belgium",
        stillInTheRace: "Y",
      },
      16615: {
        id: 16615,
        startno: 57,
        firstName: "Mads",
        lastName: "W\u00fcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamName: "Denmark",
        teamId: "Denmark",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Worlds RR Men"
  const raceID = 33

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
